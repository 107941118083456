<template>
	<div class="content">
		<WithLeftMenu :blocks="withLeftMenuContent" :class="'type2'"></WithLeftMenu>
		<section class="fullPageSectionContainer setMenuColor newForm" data-menu-color="light">
			<div class="fullPageSection">
				<div class="fullPageSection--back" style="background-color: #262E39"></div>
				<Feedback :type='2'></Feedback>
			</div>
		</section>
	</div>	
</template>


<script>
	import	WithLeftMenu from '@/components/app/WithLeftMenu'

	import 	projectsData from "@/assets/json/projects.json";

	export default {
		metaInfo: {
			title: 'Техподдержка и обслуживание сайтов, сопровождение | «‎Артрокетс»',
			meta: [
				{ vmid: 'description', name: 'description', content: 'Техническая поддержка сайтов 24/7. Техподдержка сайтов на PHP, 1С-Битрикс, Wordpress и фреймворках. Развиваем и дорабатываем интернет-ресурсы. Подключаемся на любом этапе развития сайта. Заполните форму на сайте, и мы вам перезвоним!' },
				{ vmid: 'og:title', property: 'og:title', content: 'Техподдержка и обслуживание сайтов, сопровождение | «‎Артрокетс»' },
				{ vmid: 'og:description', property: 'og:description', content: 'Техническая поддержка сайтов 24/7. Техподдержка сайтов на PHP, 1С-Битрикс, Wordpress и фреймворках. Развиваем и дорабатываем интернет-ресурсы. Подключаемся на любом этапе развития сайта. Заполните форму на сайте, и мы вам перезвоним!' },
			],
		},
		data: () => ({
			withLeftMenuContent: [
				{
					component: 'projects',
					name: 'Наши проекты',
					quantity: 3,
					quantityTable: 3,
					quantityMobile: 3,
					showPagination: false,
					data: projectsData,
					support: true,
					smallElements: true
				},{
					component: 'advantages',
					name: 'Тарифы',
					columns: 2,
					data: [
						{
							title: '2000 руб. за 1 норма-час',
							text: 'Минимум 1 норма-час.',
							upperText: 'Разовые работы',
							bottomText: 'Заказать',
							button: true,
							buttonValue: "Разовые работы",
							buttonInput: "tarif",
							form: "feedback"
						},{
							title: '1700 руб. за 1 норма-час',
							text: 'Не менее 30 норма-часов в месяц.',
							upperText: 'Абонемент',
							bottomText: 'Заказать',
							button: true,
							buttonValue: "Абонемент",
							buttonInput: "tarif",
							form: "feedback"
						}
					]
				},{
					component: 'features',
					name: 'Как проходит работа?',
					data: [
						{
							"title": "01.<br> Направляете задачи на доработку",
							"text": "По телефону, на email, Телеграмм, WhatsApp.",
							"image": "features/8.png",
						},{
							"title": "02.<br> Предлагаем решение, оцениваем стоимость и срок выполнения",
							"text": "Честно оцениваем трудозатраты специалиста на работы. Если выполнение задачи сводится к нажатию кнопки в админке сайта - за это денег не берем.",
							"image": "features/9.png",
						},{
							"title": "03.<br> После согласования и оплаты приступаем к работе",
							"text": "Первые доработки по полной предоплате, следующие — c авансом в половину стоимости.",
							"image": "features/10.png",
						},{
							"title": "04.<br> Презентуем результат и выводим на релиз",
							"text": "Тщательно тестируем все доработки, чтобы они не затронули уже действующий функционал. После согласования с клиентом, выводим результаты.",
							"image": "features/11.png",
						}
					]
				},{
					component: 'advantages',
					name: 'Особенности техподдержки<br> в Артрокетс',
					columns: 2,
					data: [
						{
							title: 'Stage-копия сайта',
							text: 'Доработки выполняем на тестовой копии основного сайта. Это нужно, чтобы посетители не отловили баги, пока идет работа над задачей.',
						},{
							title: 'Git-контроль версий',
							text: 'Используем контроль версий, если требуется подключение нескольких программистов. Так легко отслеживать изменения исходного кода и автоматизировать процесс переноса доработок на релиз.',
						},{
							title: 'Удобные каналы связи',
							text: 'Ставьте задачи по почте, Телеграмм-чату, WhatsApp или в CRM.',
						},{
							title: 'Бесплатные консультации и предложения',
							text: 'Не берем денег за консультации. Если у вас возник вопрос, поговорите с нашим  менеджером и получите все ответы на свои вопросы.',
						},{
							title: 'Время реакции на запрос - 1 час',
							text: 'В рабочее время мы всегда на связи. Работа над проектом в выходные и праздничные дни оговаривается отдельно.',
						},{
							title: 'Фиксированная цена',
							text: 'Стоимость работы никогда не изменится в большую сторону, если мы ошиблись с оценкой - недооценили объем работы и риски.',
						},{
							title: 'Бессрочная гарантия',
							text: 'Не ограничиваем вас жесткими сроками на тестирование после их сдачи. Мы уверены в качестве услуг, поэтому даем пожизненную гарантию.',
						},{
							title: 'Любые виды работ',
							text: 'Заказывайте дизайн, программирование и написание контента — все в одно “окно“.',
						},{
							title: 'Отчет о выполнении',
							text: 'В конце месяца составляем подробный отчет по каждому затраченному часу работы.',
						},{
							title: 'Не затрагиваем действующий функционал',
							text: 'Пишем код так, чтобы он не затронул то, что уже работает.',
						},{
							title: 'Доработки чужих проектов',
							text: 'Беремся за чужие проекты на любом этапе разработки.',
						},
					]
				},{
					component: 'aboutUs',
					name: 'Наша команда',
					data: [
						{
							component: 'image',
							image: require(`@/assets/img/command4.jpg`),
							additionalClass: 'margin8'
						},{
							component: 'text',
							text: `Основная компетенция нашей веб-студии: Это создание и дальнейшее развитие сайтов и интернет-магазинов. С нами работают разные компании: федеральные страховые компании, региональные банки, порталы недвижимости, медицинские центры, онлайн-магазины косметики, сложного технического оборудования и <router-link to="/portfolio/" class="redLink">многие другие</router-link>.`,
							additionalClass: 'lh28'
						}
					]
				},{
					component: 'advantages',
					name: 'Что еще можно заказать у нас?',
					columns: 2,
					data: [
						{
							title: 'Редизайн',
							text: 'Изменение дизайна устаревшего сайта с сохранением функционала.',
						},{
							title: 'Доработка сайта на 1С-Битрикс',
							text: 'Модернизация действующего проекта на CMS 1С-Битрикс.',
						},{
							title: 'Поддержка 24 на 7',
							text: 'Мониторинг сервера в постоянном режиме 24 часа в сутки, в выходные и праздничные дни.',
						},{
							title: 'Интеграция с 1С',
							text: 'Синхронизация номенклатуры, остатков и заказов.',
						},{
							title: 'Наполнение, написание текста',
							text: 'Создание полезного контента “без воды”. Заполнение сайта по заданию заказчика.',
						}
					]
				}
			]
		}),
		name: 'internet-magazin',
		components: {
			Feedback: () => import('@/components/new/Forms/Feedback'),
			WithLeftMenu
		}
	}
</script>
